import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0];

export const dictionary = {
		"/accept-invite": [~55],
		"/complete-setup": [~56],
		"/error": [57],
		"/healthz": [58],
		"/login": [59],
		"/login/error": [60],
		"/onboarding": [61],
		"/signup": [62],
		"/switch/[org]": [63],
		"/[org]": [15,[2]],
		"/[org]/apps": [16,[2]],
		"/[org]/apps/new": [19,[2]],
		"/[org]/apps/private/[id]": [20,[2]],
		"/[org]/apps/private/[id]/[version]": [21,[2,4]],
		"/[org]/apps/private/[id]/[version]/events": [22,[2,4]],
		"/[org]/apps/private/[id]/[version]/logs": [23,[2,4]],
		"/[org]/apps/[id]": [17,[2,3]],
		"/[org]/apps/[id]/credentials": [18,[2,3]],
		"/[org]/import": [24,[2,5]],
		"/[org]/import/private/[privateAppId]": [26,[2,5]],
		"/[org]/import/[appId]": [25,[2,5]],
		"/[org]/inbox/[[messageId]]": [27,[2]],
		"/[org]/link/github-app": [28],
		"/[org]/people/(list)": [29,[2,6]],
		"/[org]/people/invite": [32,[2]],
		"/[org]/people/my-profile": [33,[2]],
		"/[org]/people/(list)/pending": [30,[2,6]],
		"/[org]/people/[personId]": [31,[2]],
		"/[org]/projects/(list)": [34,[2,7]],
		"/[org]/projects/(list)/drafts": [35,[2,7]],
		"/[org]/projects/new": [42,[2]],
		"/[org]/projects/[projectId]": [36,[2,8]],
		"/[org]/projects/[projectId]/edit": [37,[2]],
		"/[org]/projects/[projectId]/environments/[[environmentId]]": [38,[2,8,9]],
		"/[org]/projects/[projectId]/events": [39,[2,8]],
		"/[org]/projects/[projectId]/resources": [40,[2,8]],
		"/[org]/projects/[projectId]/variables": [41,[2,8]],
		"/[org]/recipes/(list)": [43,[2,10]],
		"/[org]/recipes/(list)/archived": [44,[2,10]],
		"/[org]/recipes/(list)/drafts": [45,[2,10]],
		"/[org]/recipes/[recipeId]/variables": [47,[2,11]],
		"/[org]/recipes/[recipeId]/[[stepId]]": [46,[2,11,12]],
		"/[org]/resources": [48,[2]],
		"/[org]/resources/[resourceId]": [49,[2,13]],
		"/[org]/settings": [50,[2,14]],
		"/[org]/settings/developer/api-keys": [51,[2,14]],
		"/[org]/teams": [52,[2]],
		"/[org]/teams/create": [54,[2]],
		"/[org]/teams/[teamId]": [53,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';